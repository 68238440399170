import React, {CSSProperties, useEffect, useRef} from 'react';
import lottie, {AnimationItem} from 'lottie-web';

interface AnimationProps {
    data: object;
    style?: CSSProperties;
    className?: string;
    autoPlay?: boolean;
    loop?: boolean;
    onComplete?: () => void;
    startAnimation?: boolean;
}

const Animation = ({
    data,
    className,
    style,
    onComplete,
    startAnimation,
    autoPlay = false,
    loop = false,
}: AnimationProps) => {
    const animation = useRef<AnimationItem>();
    const animationContainer = useRef<HTMLDivElement>();

    useEffect(() => {
        if (startAnimation && animation.current) {
            animation.current.play();
        }
    }, [startAnimation]);

    useEffect(() => {
        if (typeof animation.current == 'undefined') {
            animation.current = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: 'svg',
                animationData: data,
                loop: loop,
                autoplay: autoPlay,
            });

            if (typeof onComplete === 'function') {
                animation.current.addEventListener('complete', onComplete);
            }

            return () => {
                animation.current.destroy();
            };
        }
    }, [loop, data, autoPlay, onComplete]);

    return <div ref={animationContainer} className={className} style={style} />;
};

export default Animation;
