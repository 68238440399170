import {ConfirmationOption} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useAppContext, useJobContext} from 'contexts';
import {PartialJob} from 'shared/types/PartialJob';
import {useFetchBenchesData} from 'components/customer/BTM/helper/useFetchBenchesData';
import {formatPrice} from 'shared/helpers';
import {Event} from 'shared/events/Event';
import {
    useGetJobPdfStatusQuery,
    useLazyGetJobCabinetVariationDetailsQuery,
} from 'components/customer/Job/store/jobApi';

const frightCostText = `NB/ The freight cost shown is just the minimum the manufacturer has set. In the event that the manufacturer decides a larger freight cost needs to be assigned, the job will be unsubmitted again for you to confirm the new cost.`;

export const useJobPDFVerification = (
    showDialog: (options: ConfirmationOption) => void,
    hideDialog: () => void,
    setTitle: (title: string) => void,
    setMessage: (message: string | JSX.Element) => void
): Event => {
    const {userProfile} = useAppContext();
    const {job} = useJobContext() as PartialJob;
    const {benches} = useFetchBenchesData();
    const [getJobCabinetVariationDetails] =
        useLazyGetJobCabinetVariationDetailsQuery();
    const [shouldPoll, setShouldPoll] = useState(false);
    const [jobPDFReady, setJobPDFReady] = useState(false);
    const [promptHidden, setPromptHidden] = useState(true);

    const {
        data: pdfStatus,
        isLoading,
        isFetching,
    } = useGetJobPdfStatusQuery(
        {jobId: job?.id, timestamp: null},
        {skip: !shouldPoll, pollingInterval: 5000}
    );

    const resolver = useRef<(v: boolean) => void>(null);

    const pdfLink = useMemo(() => {
        const pdfLinkWithName = {
            link: `<a href="/api/job/${job.id}/pdf/details" target="_blank"> Job Properties PDF</a>`,
            name: 'job properties PDF',
        };
        if (
            userProfile.hasOwnProperty('showJobPropertiesTablePdf') &&
            userProfile.showJobPropertiesTablePdf &&
            userProfile.defaultJobPropertiesPdf == 2
        ) {
            pdfLinkWithName.link = jobPDFReady
                ? `<a href="/api/job/${job.id}/pdf/details" target="_blank">Job Details PDF</a>`
                : `PDF generating... <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>`;
            pdfLinkWithName.name = 'job details PDF';
        } else if (
            userProfile.hasOwnProperty('showOrderAcknowledgementPdf') &&
            userProfile.showOrderAcknowledgementPdf &&
            userProfile.defaultJobPropertiesPdf == 3
        ) {
            pdfLinkWithName.link = `<a href="/quotePdf.php?jobId=${job.id}" target="_blank">Quote PDF</a>`;
            pdfLinkWithName.name = 'quote PDF';
        }
        return pdfLinkWithName;
    }, [userProfile, job, jobPDFReady]);

    const displayPrompt = useCallback(() => {
        let currentMessage = 0;
        showDialog({
            title: 'Show PDF',
            message: `<center>Before submitting, please validate the contents of the Job PDF.
                <br/>
                <center>${pdfLink.link}</center>
                <br/>
                Once submitted, you will be unable to make any changes to it.
                <br/>
                <br/>
                By clicking <strong>Yes, I confirm that my ${pdfLink.name} is correct.</strong></center>`,
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    show: true,
                    name: 'Yes',
                    controlledHideDialog: true,
                    variant: 'danger',
                    action: async () => {
                        if (currentMessage == 0) {
                            let jobHasVariations = false;
                            let promptMessage = '';

                            const {data} = await getJobCabinetVariationDetails(
                                {
                                    jobId: job.id,
                                },
                                true
                            );

                            if (
                                typeof data !== 'undefined' &&
                                data.length > 0
                            ) {
                                jobHasVariations = true;
                            }

                            // Checking if there are any variation requests in
                            // any of the benches
                            if (
                                benches &&
                                benches.length > 0 &&
                                !jobHasVariations
                            ) {
                                for (const bench of benches) {
                                    if (jobHasVariations) break;

                                    if (
                                        bench.comment != null &&
                                        bench?.comment != ''
                                    ) {
                                        jobHasVariations = true;
                                    }
                                }
                            }

                            if (jobHasVariations) {
                                if (job.variationsConfirmed == 0) {
                                    promptMessage = `${
                                        userProfile.jobSubmitTextWithVariations
                                    }${
                                        job.dispatchMethod != 0
                                            ? `<br />${frightCostText}`
                                            : ''
                                    }`;
                                } else {
                                    // variation confirmed by manufacturer
                                    const variationCost = formatPrice(
                                        job.totalVariationCost,
                                        job
                                    );
                                    const variationAcceptedText =
                                        userProfile.jobSubmitTextVariationsAccepted.replace(
                                            /@variationCost/gi,
                                            variationCost
                                        );
                                    promptMessage = `${variationAcceptedText}`;
                                }
                            } else {
                                promptMessage = `${
                                    userProfile.jobSubmitTextNoVariations
                                } ${
                                    job.dispatchMethod != 0
                                        ? `<br />${frightCostText}`
                                        : ''
                                }`;
                            }

                            setTitle('Submit Job?');
                            setMessage(`<center>${promptMessage}</center>`);
                            currentMessage = 1;
                        } else {
                            hideDialog();
                            resolver.current(true);
                            setPromptHidden(true);
                        }
                        setShouldPoll(false);
                    },
                },
                {
                    show: true,
                    name: 'No',
                    controlledHideDialog: true,
                    action: () => {
                        resolver.current(false);
                        setPromptHidden(true);
                        setShouldPoll(false);
                        hideDialog();
                    },
                },
            ],
        });
    }, [pdfLink, job, benches, jobPDFReady]);

    const action = useCallback((): Promise<boolean | void> => {
        return new Promise((resolve) => {
            resolver.current = resolve;
            setPromptHidden(false);
            displayPrompt(); // initially display the prompt
            if (
                userProfile.hasOwnProperty('showJobPropertiesTablePdf') &&
                userProfile.showJobPropertiesTablePdf &&
                userProfile.defaultJobPropertiesPdf == 2
            ) {
                // polling when Job Details PDF is there
                setShouldPoll(true);
            }
        });
    }, [displayPrompt, userProfile]);

    useEffect(() => {
        if (pdfStatus && !isLoading && !isFetching) {
            if (!pdfStatus.isJobQueued) {
                setShouldPoll(false);
                setJobPDFReady(true);
            }
            if (!promptHidden) {
                displayPrompt();
            }
        }
    }, [displayPrompt, pdfStatus, isLoading, isFetching, promptHidden]);

    return {
        action,
    };
};
